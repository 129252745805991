import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertsService } from 'app/services/_utils/sweet-alerts.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ImageUploadComponent), multi: true }],
})
export class ImageUploadComponent implements OnInit, OnDestroy, ControlValueAccessor {
  subscription: Subscription;

  imageUrl$ = new BehaviorSubject(null);

  @Input()
  set imageValue(value) {
    if (value) {
      this.imageUrl$.next(value);
    }
  }

  @Input()
  set isDisabled(value: boolean) {
    this.disabled = value;
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() limitLabel: any = { label: 'APP.IMAGE_SIZE_LIMIT', value: '1', unit: 'MB' };
  @Input() inputClass: string;
  @Input() classes: string;
  @Input() previewType: string = 'cover';
  @Input() limitSize: number = 1000000; // 1MB

  @Output() onDeleteImage = new EventEmitter();

  files: NgxFileDropEntry[] = [];
  file: File;
  hasDropZoneOver: boolean = false;

  disabled = false;

  form = new FormControl();

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor(private translate: TranslateService, private swalService: SweetAlertsService) { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => {
      const value = this.form.value;
      this.onChange(value);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  writeValue(value: any): void {
    if (!value && this.file) {
      this.removeFile();
    }
    this.form.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.hasDropZoneOver = false;

    const fileEntry = files[0].fileEntry as FileSystemFileEntry;
    const reader = new FileReader();
    fileEntry.file((file: File) => {
      if (file.size > this.limitSize) {
        const title = this.translate.instant('SWEETALERT.WARNING_IMAGE_SIZE_TITLE');
        const text = this.translate.instant('SWEETALERT.WARNING_IMAGE_SIZE_TEXT', {
          value: this.limitLabel['value'],
          unit: this.limitLabel['unit'],
        });
        this.swalService.swalWarning(title, text);
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageUrl$.next(reader.result);
        };
        this.form.setValue(file);
        this.file = file;
      }
    });
  }

  removeFile() {
    this.form.reset();
    this.files.splice(0, 1);
    this.file = null;
    this.imageUrl$.next(null);
    this.onDeleteImage.emit();
  }
}
