import { Component, forwardRef, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';
import {
  NgbDateStruct,
  NgbDateParserFormatter,
  NgbDate,
  NgbCalendar,
  NgbCalendarBuddhist,
} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? String(date.day).padStart(2, '0') + this.DELIMITER + String(date.month).padStart(2, '0') + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-daterange-picker',
  templateUrl: './daterange-picker.component.html',
  styleUrls: ['./daterange-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DaterangePickerComponent), multi: true },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbCalendar, useClass: NgbCalendarBuddhist },
  ],
})
export class DaterangePickerComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() placeholder = '';
  @Input() classes: string;
  @Input() inputClass: string;
  @Input() minYear = 1900;
  @Input() beYear = 543;

  @Input() set minDate(value) {
    if (value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.minPDate = new NgbDate(value.getFullYear() + this.beYear, value.getMonth() + 1, value.getDate());
    }
  }

  @Input() set maxDate(value) {
    if (value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.maxPDate = new NgbDate(value.getFullYear() + this.beYear, value.getMonth() + 1, value.getDate());
    }
  }

  minPDate: any;
  maxPDate: any;

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form: FormGroup;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      startValue: new FormControl(''),
      endValue: new FormControl(''),
    });

    this.subscription = this.form.valueChanges.subscribe((value) => {
      let pvalue: any = {};
      if (value.startValue && value.startValue.year) {
        if (value.startValue.year - this.beYear >= this.minYear) {
          pvalue.startValue = this._parseDate(value.startValue);
        }
      }
      if (value.endValue && value.endValue.year) {
        if (value.endValue.year - this.beYear >= this.minYear) {
          pvalue.endValue = this._parseDate(value.endValue);
        }
      }
      this.onChange(pvalue);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  _parseDate(value) {
    const year = value.year - this.beYear;
    const month = String(value.month).padStart(2, '0');
    const day = String(value.day).padStart(2, '0');
    const pvalue = String(new Date([year, month, day].join('-')));
    const dateJSON = new Date(pvalue).toJSON();
    return dateJSON;
  }

  writeValue(obj: any): void {
    let startValue: NgbDate;
    let endValue: NgbDate;
    if (obj) {
      const start_pdate = new Date(obj.startValue);
      const end_pdate = new Date(obj.endValue);
      startValue = new NgbDate(
        start_pdate.getFullYear() + this.beYear,
        start_pdate.getMonth() + 1,
        start_pdate.getDate()
      );
      endValue = new NgbDate(end_pdate.getFullYear() + this.beYear, end_pdate.getMonth() + 1, end_pdate.getDate());
    }
    this.form.patchValue({ startValue, endValue });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
