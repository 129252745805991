<div class="image-upload">
  <ng-container *ngIf="label" [ngTemplateOutlet]="formLabel"></ng-container>
  <div class="drop-zone-preview" [ngClass]="inputClass">
    <ngx-file-drop
      [ngClass]="{ 'file-drop-over': hasDropZoneOver }"
      (onFileOver)="hasDropZoneOver = true"
      (onFileLeave)="hasDropZoneOver = false"
      (onFileDrop)="dropped($event)"
      accept="image/*"
      multiple="false"
    >
      <ng-template ngx-file-drop-content-tmp>
        <span [data-feather]="'image'" [size]="28"></span>
      </ng-template>
    </ngx-file-drop>
    <ng-container *ngIf="imageUrl$ | async">
      <ng-container [ngSwitch]="previewType">
        <ng-container *ngSwitchCase="'cover'" [ngTemplateOutlet]="previewCover"></ng-container>
        <ng-container *ngSwitchCase="'contain'" [ngTemplateOutlet]="previewContain"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ngx-file-drop [ngClass]="'only-button'" (onFileDrop)="dropped($event)" multiple="false" *ngIf="!disabled">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <button type="button" class="btn btn-success btn-file mt-75" (click)="openFileSelector()">
        {{ "COMPONENT.SELECT_IMAGE" | translate }}
      </button>
    </ng-template>
  </ngx-file-drop>
  <ng-container *ngIf="limitLabel" [ngTemplateOutlet]="sizeLimitLabel"></ng-container>
</div>

<ng-template #formLabel>
  <label [for]="name">{{ label | translate }}</label>
</ng-template>

<ng-template #sizeLimitLabel>
  <p class="mt-25 mb-0 text-muted text-center font-size-sm" *ngIf="!disabled">
    {{ limitLabel.label | translate : { value: limitLabel.value, unit: limitLabel.unit } }}
  </p>
</ng-template>

<ng-template #previewCover>
  <div class="preview" style.background-image="url({{ imageUrl$ | async }})">
    <button type="button" aria-label="Close" class="close" (click)="removeFile(i)" *ngIf="!disabled">
      <span [data-feather]="'x'"></span>
    </button>
  </div>
</ng-template>

<ng-template #previewContain>
  <div class="preview">
    <div class="image-contain">
      <img [src]="imageUrl$ | async" />
    </div>
    <button type="button" aria-label="Close" class="close" (click)="removeFile(i)" *ngIf="!disabled">
      <span [data-feather]="'x'"></span>
    </button>
  </div>
</ng-template>
