import { Component, Input, OnInit } from '@angular/core';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {
  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() data: any;
  @Input() icon: string;
  @Input() iconClass: string;
  @Input() classes: string;
  @Input() decimals: number = 0;
  @Input() html: boolean = false;
  @Input() mask: string;
  @Input() separator = ',';
  @Input() isLoading: boolean;
  constructor() { }

  ngOnInit(): void { }
}
