export enum UserRole {
  SYSTEM_ADMIN = 'System Admin',
  ADMIN = 'Administrator',
  DOCTOR = 'Doctor',
  STAFF = 'Staff',
}
export enum UserRoleId {
  SYSTEM_ADMIN = 1,
  ADMIN = 2,
  DOCTOR = 3,
  STAFF = 4,
}
export enum Permission {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
export enum PermissionModule {
  POS = 'pos',
  DASHBOARD = 'dashboard',
  REPORT = 'report',
  REPORT_INCOME = 'report-income',
  REPORT_CASE = 'report-case',
  APPOINTMENT = 'appointment',
  MEDICAL_RECORD = 'medical-record',
  MEDICAL_RECORD_REGISTER = 'medical-record-register',
  MEDICAL_RECORD_CUSTOMER_INFO = 'medical-record-customer-info',
  MEDICAL_RECORD_OPEN_OPD = 'medical-record-open-opd',
  MEDICAL_RECORD_OPD_HISTORY = 'medical-record-opd-history',
  MEDICAL_RECORD_RECEIPT = 'medical-record-receipt',
  MEDICAL_RECORD_MEDICAL_CERTIFICATE = 'medical-record-medical-certificate',
  MEDICAL_RECORD_EXAMINATION_FILE = 'medical-record-examination-file',
  EXAMINATION_ROOM = 'examination-room',
  EXAMINATION_ROOM_INFO = 'examination-room-info',
  SETTING = 'setting',
  SETTING_GENERAL = 'setting-general',
  SETTING_GENERAL_CLINIC_INFORMATION = 'setting-general-clinic-information',
  SETTING_GENERAL_EXAMINATIONS_ROOMS = 'setting-general-examinations-rooms',
  SETTING_GENERAL_USERS = 'setting-general-users',
  SETTING_PRODUCT_AND_SERVICE = 'setting-product-and-service',
  SETTING_PRODUCT_AND_SERVICE_MEDICINE_EQUIPMENT = 'setting-product-and-service-medicine-equipment',
  SETTING_PRODUCT_AND_SERVICE_PROCEDURE_AND_FEE = 'setting-product-and-service-procedure-and-fee',
  SETTING_PRODUCT_AND_SERVICE_PRODUCT = 'setting-product-and-service-product',
  SETTING_PRODUCT_AND_SERVICE_STOCK = 'setting-product-and-service-stock',
  PERMISSION = 'permission',
}

export const Permissions = [
  {
    id: 1,
    user_role_id: 1,
    name: 'pos',
    title: 'MENU.POS',
    is_module: true,
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      { name: 'update', is_active: true },
    ],
  },
  {
    id: 2,
    user_role_id: 1,
    name: 'dashboard',
    title: 'MENU.DASHBOARD',
    is_module: true,
    permissions: [{ name: 'read', is_active: true }],
  },
  {
    id: 3,
    user_role_id: 1,
    name: 'report',
    title: 'MENU.REPORTS.SECTION',
    is_module: true,
    permissions: [{ name: 'read', is_active: true }],
  },
  {
    id: 4,
    user_role_id: 1,
    module_id: 3,
    name: 'report-income',
    title: 'MENU.REPORTS.INCOME_REPORT',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
    ],
  },
  {
    id: 5,
    user_role_id: 1,
    module_id: 3,
    name: 'report-case',
    title: 'MENU.REPORTS.CASE_REPORT',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
    ],
  },
  {
    id: 6,
    user_role_id: 1,
    name: 'appointment',
    title: 'MENU.APPOINTMENT',
    is_module: true,
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 7,
    user_role_id: 1,
    name: 'medical-record',
    title: 'MENU.MEDICAL_RECORD.SECTION',
    is_module: true,
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 8,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-register',
    title: 'MENU.MEDICAL_RECORD.REGISTER',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 9,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-customer-info',
    title: 'MENU.MEDICAL_RECORD.INFO',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 10,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-open-opd',
    title: 'TITLE.NEW_OPD_CARD',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 11,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-opd-history',
    title: 'APP.OPD_HISTORY',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'update', is_active: true },
    ],
  },
  {
    id: 12,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-receipt',
    title: 'APP.RECEIPT',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 13,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-medical-certificate',
    title: 'APP.MEDICAL_CERTIFICATE',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'update', is_active: true },
    ],
  },
  {
    id: 14,
    user_role_id: 1,
    module_id: 7,
    name: 'medical-record-examination-file',
    title: 'APP.EXAMINATION_FILE',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 15,
    user_role_id: 1,
    name: 'examination-room',
    title: 'MENU.EXAMINATION_ROOM',
    is_module: true,
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 16,
    user_role_id: 1,
    module_id: 15,
    name: 'examination-room-info',
    title: 'TITLE.EXAMINATION_ROOM_INFO',
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 17,
    user_role_id: 1,
    name: 'setting',
    title: 'MENU.SETTINGS.SECTION',
    is_module: true,
    permissions: [{ name: 'read', is_active: true }],
  },
  {
    id: 18,
    user_role_id: 1,
    module_id: 17,
    name: 'setting-general',
    title: 'MENU.SETTINGS.GENERAL_INFORMATION.SECTION',
    has_sub: true,
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 19,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 18,
    name: 'setting-general-clinic-information',
    title: 'MENU.SETTINGS.GENERAL_INFORMATION.CLINIC_INFORMATION',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 20,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 18,
    name: 'setting-general-examinations-rooms',
    title: 'MENU.SETTINGS.GENERAL_INFORMATION.EXAMINATION_ROOM',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 21,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 18,
    name: 'setting-general-users',
    title: 'MENU.SETTINGS.GENERAL_INFORMATION.USER',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 22,
    user_role_id: 1,
    module_id: 17,
    name: 'setting-product-and-service',
    title: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.SECTION',
    has_sub: true,
    permissions: [
      { name: 'read', is_active: true },
      // { name: 'create', is_active: true },
      // { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
  {
    id: 23,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 22,
    name: 'setting-product-and-service-medicine-equipment',
    title: 'APP.MEDICINE_AND_EQUIPMENT',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 24,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 22,
    name: 'setting-product-and-service-procedure-and-fee',
    title: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.PROCEDURE_AND_FEE.SECTION',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 25,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 22,
    name: 'setting-product-and-service-product',
    title: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.PRODUCT.SECTION',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      { name: 'delete', is_active: true },
    ],
  },
  {
    id: 26,
    user_role_id: 1,
    module_id: 17,
    sub_module_id: 22,
    name: 'setting-product-and-service-stock',
    title: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.STOCK.SECTION',
    permissions: [
      { name: 'read', is_active: true },
      { name: 'create', is_active: true },
      { name: 'update', is_active: true },
      // { name: 'delete', is_active: true },
    ],
  },
];

export const PermissionActions = [
  { name: 'read', title: 'ROLE_MANAGEMENT.PERMISSION_HEADER.VIEW' },
  { name: 'create', title: 'ROLE_MANAGEMENT.PERMISSION_HEADER.CREATE' },
  { name: 'update', title: 'ROLE_MANAGEMENT.PERMISSION_HEADER.EDIT' },
  { name: 'delete', title: 'ROLE_MANAGEMENT.PERMISSION_HEADER.DELETE' },
];
