<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <ng-select
    [id]="name"
    [appendTo]="appendTo"
    class="{{ inputClass }}"
    [ngClass]="{ error: isError, require: isRequire, 'body-z-index': appendTo === 'body' }"
    [formControl]="form"
    [items]="options$ | async"
    bindLabel="{{ bindLabel }}"
    bindValue="{{ bindValue }}"
    [placeholder]="placeholder | translate"
    [loading]="isLoading"
    [clearable]="clearable"
    [searchable]="searchable"
    [typeahead]="search$"
  ></ng-select>
  <span class="error" *ngIf="isError">{{ errorMessage | translate }}</span>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name"
      >{{ label | translate }}
      <span *ngIf="isRequire" class="text-danger">*</span>
      <span *ngIf="addonLabel && addonLabel != '*'" class="{{ addonLabelClass }}">({{ addonLabel | translate }})</span>
      <span *ngIf="addonLabel && addonLabel === '*'" class="{{ addonLabelClass }}">{{ addonLabel | translate }}</span>
    </label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <span *ngIf="isRequire" class="text-danger">*</span></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>
