import { Component, HostListener, OnInit } from '@angular/core';
import { ScreenResizeService } from 'app/services/_utils/screen-resize.service';

@Component({
  selector: 'screen-size-detector',
  templateUrl: './screen-size-detector.component.html',
  styleUrls: ['./screen-size-detector.component.scss']
})
export class ScreenSizeDetectorComponent implements OnInit {

  constructor(
    private resizeSvc: ScreenResizeService
  ) { }

  @HostListener("window:resize", ['$event'])
  onResize($event) {
    this.detectScreenSize($event);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    const payload = {
      target: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      }
    }
    this.detectScreenSize(payload);
  }

  detectScreenSize($event: any) {
    const payload = {
      width: $event.target.innerWidth,
      height: $event.target.innerHeight
    }
    this.resizeSvc.onResize(payload);
  }
}
