import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LabelMode } from 'utils/label-mode.enum';
import { GenerateRandom } from 'utils/random';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextareaComponent), multi: true }],
})
export class TextareaComponent implements OnInit, OnDestroy {

  @ViewChild('textareaBox') textareaBox: ElementRef;

  @Input()
  set isDisabled(value: boolean) {
    if (value) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set error(value: any) {
    this.isError = false;
    this.errorMessage = '';

    if (value) {
      this.isError = true;
      const errorKey = Object.keys(value)[0];

      switch (errorKey) {
        case 'required':
          this.errorMessage = 'VALIDATOR.REQUIRED';
          return;
        default:
          this.errorMessage = Array.isArray(value) ? value[0] : value;
      }
    }
  }

  @Input() name = GenerateRandom(6);
  @Input() label: string;
  @Input() addonLabel: string = '';
  @Input() addonLabelClass: string = '';
  @Input() placeholder = '';
  @Input() rows = 2;
  @Input() classes: string;

  @Input() set labelMode(value: LabelMode) {
    this.mode = value;
  }

  LabelMode = LabelMode;
  mode: LabelMode = LabelMode.DEFAULT;

  form = new FormControl();
  subscription: Subscription;

  errorMessage = '';
  isError = false;

  onChange = (value: string) => { };
  onTouched = (value: string) => { };

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.form.valueChanges.subscribe(value => {
      this.onChange(value);
      // if (this.textareaBox) {
      //   setTimeout(() => {
      //     this.textareaBox.nativeElement.style.height = 'auto';
      //     if (this.textareaBox.nativeElement.scrollHeight > 50) {
      //       this.textareaBox.nativeElement.style.height = `${this.textareaBox.nativeElement.scrollHeight + 3}px`;
      //     }
      //   }, 100);
      // }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  writeValue(obj: any): void {
    // Use patchValue fot unmatch key
    this.form.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
}
