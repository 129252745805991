import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { apiPaths } from './_api-path';

@Injectable({
  providedIn: 'root',
})
export class MasterService {
  private api = apiPaths;

  constructor(private http$: HttpClient) {}

  _setParams(value) {
    let params = new HttpParams();
    for (const key in value) {
      params = params.append(key, value[key]);
    }
    return params;
  }

  _setFormData(object_key, value) {
    let formData = new FormData();
    for (const key in value) {
      let obj = value[key];
      if (Array.isArray(obj)) {
        obj.forEach((e, i) => {
          formData.append(`${object_key}[${key}][]`, e);
        });
      } else if (Array.isArray(value)) {
        for (const key_i in obj) {
          let obj_i = obj[key_i];
          if (Array.isArray(obj_i)) {
            obj_i.forEach((ej, j) => {
              formData.append(`${object_key}[][${key_i}][]`, ej);
            });
          } else {
            formData.append(`${object_key}[][${key_i}]`, obj_i);
          }
        }
      } else {
        formData.append(`${object_key}[${key}]`, value[key]);
      }
    }
    return formData;
  }

  // PATIENT
  getNamePrefixes(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.prefixesApi}?${params}`).pipe(retry(1));
  }

  getBloodGroups(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.bloodGroupsApi}?${params}`).pipe(retry(1));
  }

  getGenders(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.gendersApi}?${params}`).pipe(retry(1));
  }

  getMaritalStatuses(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.maritalStatusesApi}?${params}`).pipe(retry(1));
  }

  getRaces(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.racesApi}?${params}`).pipe(retry(1));
  }

  getCareers(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.careersApi}?${params}`).pipe(retry(1));
  }

  getEducations(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.educationsApi}?${params}`).pipe(retry(1));
  }

  getSmokingConditions(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.smokingConditionsApi}?${params}`).pipe(retry(1));
  }

  getAlcoholConditions(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.alcoholConditionsApi}?${params}`).pipe(retry(1));
  }

  getAllergicConditions(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.allergicConditionsApi}?${params}`).pipe(retry(1));
  }

  getMedicalTreatmentRights(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalTreatmentRightsApi}?${params}`).pipe(retry(1));
  }

  getHospitals(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.hospitalApi}?${params}`).pipe(retry(1));
  }

  // ADDRESS
  getTambons(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.tambonsApi}?${params}`).pipe(retry(1));
  }

  getAmphurs(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.amphursApi}?${params}`).pipe(retry(1));
  }

  getProvinces(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.provincesApi}?${params}`).pipe(retry(1));
  }

  // OPD
  getOpdCardRequests(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.opdCardRequestsApi}?${params}`).pipe(retry(1));
  }

  getOpdCardStatusMasters(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.opdCardStatusMasterApi}?${params}`).pipe(retry(1));
  }
  getCertificateTypes(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalCertificateTypesApi}?${params}`).pipe(retry(1));
  }

  // GENERAL INFORMATION
  getClinics(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.clinicsApi}?${params}`).pipe(retry(1));
  }
  clinic(id?): Observable<any> {
    return this.http$.get(`${this.api.clinicsApi}/${id}`).pipe(retry(1));
  }

  clinicBankAccounts(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.clinicBankAccountsApi}?${params}`).pipe(retry(1));
  }

  getBanks(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.banksApi}?${params}`).pipe(retry(1));
  }
  getBankAccountUsages(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.bankAccountUsagesApi}?${params}`).pipe(retry(1));
  }

  getRole(id: number): Observable<any> {
    // const params = this._setParams(filters);
    return this.http$.get(`${this.api.rolesApi}/${id}`).pipe(retry(1));
  }
  getRoles(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.rolesApi}?${params}`).pipe(retry(1));
  }
  updatePermission(id, payload?): Observable<any> {
    const formData = { role: payload };
    return this.http$.put(`${this.api.rolesApi}/${id}`, formData).pipe(retry(1));
  }

  getExaminationRooms(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.roomsApi}?${params}`).pipe(retry(1));
  }

  getUsers(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.usersApi}?${params}`).pipe(retry(1));
  }

  // PRODUCTS AND SERVICES
  getSkuMasters(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.skuMastersApi}?${params}`).pipe(retry(1));
  }
  skuMasters(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.skuMastersApi}/${id}?${params}`).pipe(retry(1));
  }
  getUnits(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.unitsMasterApi}?${params}`).pipe(retry(1));
  }

  getGoodsMasters(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsMastersApi}?${params}`).pipe(retry(1));
  }
  goodsMasters(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsMastersApi}/${id}?${params}`).pipe(retry(1));
  }

  getGoodsCategories(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsCategoriesApi}?${params}`).pipe(retry(1));
  }

  getGoodsGroups(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsGroupsApi}?${params}`).pipe(retry(1));
  }

  getGoodsTypes(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsTypesApi}?${params}`).pipe(retry(1));
  }

  getGoodsSets(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsSetsApi}?${params}`).pipe(retry(1));
  }
  goodsSet(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.goodsSetsApi}/${id}?${params}`).pipe(retry(1));
  }

  getActingTypes(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.actingTypesApi}?${params}`).pipe(retry(1));
  }

  // PRODUCTS AND SERVICES > OPERATIVES
  getOperativeGroups(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.operativeGroupsApi}?${params}`).pipe(retry(1));
  }

  getOperatives(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.operativesApi}?${params}`).pipe(retry(1));
  }

  getDoctorFees(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.doctorFeesApi}?${params}`);
  }
  doctorFee(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.doctorFeesApi}/${id}?${params}`).pipe(retry(1));
  }
  getAppointmentStatus(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.appointmentStatusApi}?${params}`).pipe(retry(1));
  }
  getPaymentTypes(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.paymentTypesApi}?${params}`).pipe(retry(1));
  }

  getStockImports(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.stockImportApi}?${params}`);
  }
  getStockExports(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.stockExportApi}?${params}`);
  }

  getStockExportReason(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.stockExportReasonApi}?${params}`);
  }
}
