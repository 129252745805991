<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="input"></ng-container>
</ng-container>

<ng-template #input>
  <div class="datepicker-group">
    <nz-date-picker name="basicDate" [ngClass]="{'is-invalid': isError}" [formControl]="form" [nzFormat]="format" [nzMode]="nzMode" [nzPlaceHolder]="placeholder | translate" [nzDisabledDate]="disabledDate" [nzAllowClear]="false" [nzSuffixIcon]="nzSuffixIcon"></nz-date-picker>
  </div>
  <ng-container [ngTemplateOutlet]="error"></ng-container>
</ng-template>

<ng-template #default>
  <div class="form-group" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group form-group-inline" [ngClass]="classes">
    <label [for]="name">{{ label | translate }} <ng-container [ngTemplateOutlet]="subLabel"></ng-container></label>
    <ng-container [ngTemplateOutlet]="input"></ng-container>
  </div>
</ng-template>

<ng-template #error>
  <span class="error" *ngIf="isError">{{ errorMessage | translate }}</span>
</ng-template>

<ng-template #subLabel>
  <span class="text-muted" *ngIf="addonLabel != ''">({{ addonLabel | translate }})</span>
</ng-template>

<ng-template #nzSuffixIcon>
  <span [data-feather]="'calendar'" [size]="17"></span>
</ng-template>