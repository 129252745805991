import { CoreMenu } from '@core/types';
import { Permission, PermissionModule, UserRole } from 'utils/role.enum';

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'dashboard',
    title: 'Dashboard',
    translate: 'MENU.DASHBOARD',
    type: 'item',
    icon: 'layout',
    url: 'dashboard',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.DASHBOARD}:${Permission.READ}`],
  },
  // Report
  {
    id: 'report',
    type: 'section',
    title: 'Reports',
    translate: 'MENU.REPORTS.SECTION',
    icon: 'file-text',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.REPORT}:${Permission.READ}`],
    children: [
      {
        id: 'report-income',
        title: 'Income Report',
        translate: 'MENU.REPORTS.INCOME_REPORT',
        type: 'item',
        icon: 'file-minus',
        url: 'reports/income-report',
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [`${PermissionModule.REPORT_INCOME}:${Permission.READ}`],
      },
      {
        id: 'report-case',
        title: 'Case Report',
        translate: 'MENU.REPORTS.CASE_REPORT',
        type: 'item',
        icon: 'pie-chart',
        url: 'reports/case-report',
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [`${PermissionModule.REPORT_CASE}:${Permission.READ}`],
      },
      // {
      //   id: 'sso-report',
      //   title: 'SSO Report',
      //   translate: 'MENU.REPORTS.SSO_REPORT',
      //   type: 'item',
      //   icon: 'bookmark',
      //   url: 'reports/sso-report'
      // },
      // {
      //   id: 'expense-report',
      //   title: 'Expense Report',
      //   translate: 'MENU.REPORTS.EXPENSE_REPORT',
      //   type: 'item',
      //   icon: 'file-minus',
      //   url: 'reports/expense-report'
      // },
      // {
      //   id: 'customer-reports',
      //   title: 'Customer Reports',
      //   translate: 'MENU.REPORTS.CUSTOMER_REPORTS.SECTION',
      //   type: 'collapsible',
      //   icon: 'user',
      //   children: [
      //     {
      //       id: 'growth-rate-over-time',
      //       title: 'Customer Growth Rate Over Time',
      //       translate: 'MENU.REPORTS.CUSTOMER_REPORTS.GROWTH_RATE_OVER_TIME',
      //       type: 'item',
      //       url: 'reports/customer-reports/growth-rate-over-time'
      //     },
      //     {
      //       id: 'number-of-new-customers',
      //       title: 'Number of New Customers',
      //       translate: 'MENU.REPORTS.CUSTOMER_REPORTS.NUMBER_OF_NEW_CUSTOMER',
      //       type: 'item',
      //       url: 'reports/customer-reports/number-of-new-customers'
      //     },
      //     {
      //       id: 'number-of-old-customers-received-service-again',
      //       title: 'Number of Old Customers Received the Service Again',
      //       translate: 'MENU.REPORTS.CUSTOMER_REPORTS.NUMBER_OF_OLD_CUSTOMERS_RECEIVED_SERVICE_AGAIN',
      //       type: 'item',
      //       url: 'reports/customer-reports/number-of-old-customers-received-service-again'
      //     },
      //   ]
      // }
    ],
  },
  // Medical Record
  {
    id: 'medical-record',
    type: 'section',
    title: 'Medical Record',
    translate: 'MENU.MEDICAL_RECORD.SECTION',
    icon: 'clipboard',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.MEDICAL_RECORD}:${Permission.READ}`],
    children: [
      // {
      //   id: 'register',
      //   title: 'Register',
      //   translate: 'MENU.MEDICAL_RECORD.REGISTER',
      //   type: 'item',
      //   icon: 'edit-3',
      //   url: 'medical-record/customer-patient/register',
      // },
      {
        id: 'customer-patient',
        title: 'Customer & Patient',
        translate: 'MENU.MEDICAL_RECORD.CUSTOMER_PATIENT',
        type: 'item',
        icon: 'user',
        url: 'medical-record/customer-patient',
        // exceptMatches: ['/medical-record/customer-patient/register'],
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [
          `${PermissionModule.MEDICAL_RECORD_REGISTER}:${Permission.READ}`,
          `${PermissionModule.MEDICAL_RECORD_CUSTOMER_INFO}:${Permission.READ}`,
        ],
      },
    ],
  },
  // Examination Room
  {
    id: 'examination-room',
    title: 'Examination Room',
    translate: 'MENU.EXAMINATION_ROOM',
    type: 'item',
    icon: 'heart',
    url: 'medical-record/examination-room',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.EXAMINATION_ROOM}:${Permission.READ}`],
  },
  // Appointment
  {
    id: 'appointment',
    title: 'Appointment',
    translate: 'MENU.APPOINTMENT',
    type: 'item',
    icon: 'calendar',
    url: 'appointment',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.APPOINTMENT}:${Permission.READ}`],
  },
  // Stock Management
  // {
  //   id: 'stock-management',
  //   type: 'section',
  //   title: 'Stock Management',
  //   translate: 'MENU.STOCK_MANAGEMENT.SECTION',
  //   icon: 'package',
  //   children: [
  //     {
  //       id: 'purchase',
  //       title: 'Purchase',
  //       translate: 'MENU.STOCK_MANAGEMENT.PURCHASE',
  //       type: 'item',
  //       icon: 'file-minus',
  //       url: 'stock-management/purchase'
  //     },
  //     {
  //       id: 'medicine-and-equipment-list',
  //       title: 'Medicine / Equipment List',
  //       translate: 'MENU.STOCK_MANAGEMENT.MEDICINE_AND_EQUIPMENT_LIST',
  //       type: 'item',
  //       icon: 'minus-circle',
  //       url: 'stock-management/medicine-and-equipment-list'
  //     },
  //     {
  //       id: 'product',
  //       title: 'Product List',
  //       translate: 'MENU.STOCK_MANAGEMENT.PRODUCT_LIST',
  //       type: 'item',
  //       icon: 'package',
  //       url: 'stock-management/product'
  //     },
  //   ]
  // },
  // Stock Management
  {
    id: 'setting',
    type: 'section',
    title: 'Settings',
    translate: 'MENU.SETTINGS.SECTION',
    icon: 'settings',
    // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
    permission: [`${PermissionModule.SETTING}:${Permission.READ}`],
    children: [
      // General Information
      {
        id: 'setting-general',
        title: 'General Information',
        translate: 'MENU.SETTINGS.GENERAL_INFORMATION.SECTION',
        type: 'collapsible',
        icon: 'home',
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [`${PermissionModule.SETTING_GENERAL}:${Permission.READ}`],
        children: [
          {
            id: 'setting-general-clinic-information',
            title: 'Clinic Information',
            translate: 'MENU.SETTINGS.GENERAL_INFORMATION.CLINIC_INFORMATION',
            type: 'item',
            url: 'settings/general-information/clinic-information',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_GENERAL_CLINIC_INFORMATION}:${Permission.READ}`],
          },
          // {
          //   id: 'doctor-information',
          //   title: 'Doctor Information',
          //   translate: 'MENU.SETTINGS.GENERAL_INFORMATION.DOCTOR_INFORMATION',
          //   type: 'item',
          //   url: 'settings/general-information/doctor-information'
          // },
          {
            id: 'setting-general-examinations-rooms',
            title: 'Examination Room',
            translate: 'MENU.SETTINGS.GENERAL_INFORMATION.EXAMINATION_ROOM',
            type: 'item',
            url: 'settings/general-information/examination-rooms',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_GENERAL_EXAMINATIONS_ROOMS}:${Permission.READ}`],
          },
          {
            id: 'setting-general-users',
            title: 'User',
            translate: 'MENU.SETTINGS.GENERAL_INFORMATION.USER',
            type: 'item',
            url: 'settings/general-information/users',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_GENERAL_USERS}:${Permission.READ}`],
          },
        ],
      },
      // Product & Service
      {
        id: 'setting-product-and-service',
        title: 'Product & Service',
        translate: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.SECTION',
        type: 'collapsible',
        icon: 'package',
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [`${PermissionModule.SETTING_PRODUCT_AND_SERVICE}:${Permission.READ}`],
        children: [
          // Medicine / Equipment
          {
            id: 'setting-product-and-service-medicine-equipment',
            title: 'Medicine / Equipment',
            translate: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.MEDICINE_AND_EQUIPMENT.SECTION',
            type: 'item',
            // icon: 'minus-circle',
            url: 'settings/product-and-service/medicine-equipment',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_PRODUCT_AND_SERVICE_MEDICINE_EQUIPMENT}:${Permission.READ}`],
          },
          // Procedure / Doctor Fee
          {
            id: 'setting-product-and-service-procedure-and-fee',
            title: 'Procedure / Doctor Fee',
            translate: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.PROCEDURE_AND_FEE.SECTION',
            type: 'item',
            // icon: 'folder-minus',
            url: 'settings/product-and-service/procedure-and-fee',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_PRODUCT_AND_SERVICE_PROCEDURE_AND_FEE}:${Permission.READ}`],
          },
          // Product
          {
            id: 'setting-product-and-service-product',
            title: 'Product',
            translate: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.PRODUCT.SECTION',
            type: 'item',
            // icon: 'package',
            url: 'settings/product-and-service/product',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            permission: [`${PermissionModule.SETTING_PRODUCT_AND_SERVICE_PRODUCT}:${Permission.READ}`],
          },
          // Stocks
          {
            id: 'setting-product-and-service-stock',
            title: 'Stocks',
            translate: 'MENU.SETTINGS.PRODUCT_AND_SERVICE.STOCK.SECTION',
            type: 'item',
            // icon: 'package',
            url: 'settings/product-and-service/stocks',
            // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
            // permission: [`${PermissionModule.SETTING_PRODUCT_AND_SERVICE_PRODUCT}:${Permission.READ}`],
          },
        ],
      },
      {
        id: 'permission',
        title: 'Permission',
        translate: 'MENU.SETTINGS.PERMISSION.SECTION',
        type: 'item',
        icon: 'user-x',
        url: 'settings/permission',
        // role: [UserRole.SYSTEM_ADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.STAFF],
        permission: [`${PermissionModule.PERMISSION}:${Permission.READ}`],
      },
      // Document
      // {
      //   id: 'document',
      //   title: 'Document',
      //   translate: 'MENU.SETTINGS.DOCUMENT.SECTION',
      //   type: 'collapsible',
      //   icon: 'file',
      //   children: [
      //     {
      //       id: 'medical-certification',
      //       title: 'Medical Certificate',
      //       translate: 'MENU.SETTINGS.DOCUMENT.MEDICAL_CERTIFICATE',
      //       type: 'item',
      //       url: 'settings/document/medical-certification'
      //     },
      //   ]
      // }
    ],
  },
];
