<ngx-file-drop
  class="drop-mode"
  [ngClass]="{ 'file-drop-over': hasDropZoneOver }"
  (onFileOver)="hasDropZoneOver = true"
  (onFileLeave)="hasDropZoneOver = false"
  (onFileDrop)="dropped($event)"
  multiple="true"
  accept="image/* , .pdf"
>
  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
    <p class="mb-0 text-muted">
      {{ "APP.FILE_PLACEHOLDER_2" | translate }}
      <a class="primary file-link" (click)="openFileSelector()">{{ "COMPONENT.BROWSE_FILE" | translate }}</a>
    </p>
  </ng-template>
</ngx-file-drop>
