export const locale = {
  lang: 'th',
  data: {
    LOGIN: {
      WELCOME_TEXT: 'ยินดีต้อนรับสู่',
      WELCOME_DESC: 'กรุณาลงชื่อเข้าใช้บัญชีของคุณและเริ่มใช้งานระบบ',
      USERNAME: 'ชื่อผู้ใช้งาน',
      EMAIL: 'อีเมล',
      PASSWORD: 'รหัสผ่าน',
      SIGN_IN: 'เข้าสู่ระบบ',
      SIGN_OUT: 'ออกจากระบบ',
      INVALID_LOGIN: 'อีเมลหรือรหัสผ่านที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบและลองอีกครั้ง',
    },
    MENU: {
      DASHBOARD: 'แดชบอร์ด',
      MEDICAL_RECORD: {
        SECTION: 'เวชระเบียน',
        REGISTER: 'ลงทะเบียนใหม่',
        CUSTOMER_PATIENT: 'ข้อมูลลูกค้า / ผู้ป่วย',
        INFO: 'รายการข้อมูลผู้ป่วย',
      },
      EXAMINATION_ROOM: 'ห้องตรวจรักษา',
      QUEUE: 'คิวรับบริการ',
      APPOINTMENT: 'การนัดหมาย',
      REPORTS: {
        SECTION: 'รายงาน',
        INCOME_REPORT: 'รายงานรายรับ',
        EXPENSE_REPORT: 'รายงานรายจ่าย',
        CASE_REPORT: 'รายงานจำนวนเคส',
        SSO_REPORT: 'รายงานประกันสังคม',
        CUSTOMER_REPORTS: {
          SECTION: 'รายงานลูกค้า',
          GROWTH_RATE_OVER_TIME: 'อัตราการเพิ่มของลูกค้าตามช่วงเวลา',
          NUMBER_OF_NEW_CUSTOMER: 'จำนวนลูกค้าใหม่',
          NUMBER_OF_OLD_CUSTOMERS_RECEIVED_SERVICE_AGAIN: 'จำนวนลูกค้าเก่าที่มารับบริการซ้ำ',
        },
      },
      STOCK_MANAGEMENT: {
        SECTION: 'จัดการสต๊อกสินค้า',
        PURCHASE: 'ใบซื้อเข้า',
        MEDICINE_AND_EQUIPMENT_LIST: 'รายการยา / อุปกรณ์',
        PRODUCT_LIST: 'รายการสินค้า',
      },
      SETTINGS: {
        SECTION: 'การตั้งค่า',
        GENERAL_INFORMATION: {
          SECTION: 'ข้อมูลทั่วไป',
          CLINIC_INFORMATION: 'ข้อมูลคลินิก',
          DOCTOR_INFORMATION: 'ข้อมูลแพทย์',
          EXAMINATION_ROOM: 'ห้องตรวจ',
          USER: 'ผู้ใช้งาน',
          ROLE_AND_PERMISSION: 'บทบาทและสิทธิ์การใช้งาน',
          LINE_NOTIFICATION: 'การแจ้งเตือนผ่าน LINE',
        },
        PRODUCT_AND_SERVICE: {
          SECTION: 'สินค้าและบริการ',
          MEDICINE_AND_EQUIPMENT: {
            SECTION: 'ยา',
            MEDICINE_AND_EQUIPMENT_LIST: 'รายการ',
            MEDICINE_PACK: 'การจัดชุดยา',
            MEDICINE_AND_EQUIPMENT_CATEGORY: 'ตั้งค่าหมวดหมู่',
            MEDICINE_AND_EQUIPMENT_TYPE: 'ตั้งค่าประเภท',
          },
          PROCEDURE_AND_FEE: {
            SECTION: 'หัตถการ / ค่าแพทย์',
            MEDICAL_PROCEDURE_LIST: 'รายการหัตถการ',
            MEDICAL_PROCEDURE_CATEGORY: 'หมวดหมู่หัตถการ',
            DOCTOR_FEE: 'ค่าแพทย์',
          },
          PRODUCT: {
            SECTION: 'สินค้า',
            PRODUCT_LIST: 'รายการสินค้า',
            PRODUCT_CATEGORY: 'หมวดหมู่สินค้า',
          },
          STOCK: {
            SECTION: 'สต๊อกสินค้า',
            IMPORT_STOCK_LIST: 'รายการสินค้านำเข้า',
            EXPORT_STOCK_LIST: 'รายการสินค้านำออก',
          },
        },
        DOCUMENT: {
          SECTION: 'เอกสาร',
          MEDICAL_CERTIFICATE: 'ใบรับรองแพทย์',
        },
        PERMISSION: {
          SECTION: 'จัดการสิทธิ์เข้าถึง',
          // CLINIC_INFORMATION: 'Clinic Information',
          // DOCTOR_INFORMATION: 'Doctor Information',
          // EXAMINATION_ROOM: 'Examination Room',
          // USER: 'User',
          // ROLE_AND_PERMISSION: 'Role & Permission',
          // LINE_NOTIFICATION: 'LINE Notification',
        },
      },
      POS: 'หน้าจอขาย',
      QUEUE_DISPLAY: 'หน้าจอแสดงคิว',
    },
    TITLE: {
      CUSTOMER_PATIENT_LIST: 'รายชื่อลูกค้า / ผู้ป่วย',
      CUSTOMER_PATIENT_REGISTER: 'ลงทะเบียนลูกค้า / ผู้ป่วยใหม่',
      CUSTOMER_PATIENT_DETAIL: 'ข้อมูลลูกค้า / ผู้ป่วย',
      EDIT_CUSTOMER_PATIENT_INFORMATION: 'แก้ไขข้อมูลลูกค้า / ผู้ป่วย',
      OPD_HISTORY: 'ประวัติการตรวจ',
      OPERATION_RECORD: 'บันทึกการตรวจ',
      TREATMENT_MEDICATION_LIST: 'รายการรักษา-จ่ายยา',
      RECEIPT: 'ใบเสร็จ',
      RECEIPT_TITLE: 'ใบเสร็จรับเงิน',
      MEDICAL_CERTIFICATE: 'ใบรับรองแพทย์',
      MEDICAL_CERTIFICATE_HISTORY: 'ประวัติใบรับรองแพทย์',
      NEW_OPD_CARD: 'เปิด OPD Card',
      EXAMINATION_ROOM_LIST: 'รายการห้องตรวจ',
      APPOINTMENT_LIST: 'รายการนัดหมาย',
      OPD_CARD: 'OPD Card',
      COMPARE_HISTORY: 'เทียบประวัติเก่า',
      INCOME_REPORT: 'รายงานรายรับ',
      EXPENSE_REPORT: 'รายงานรายจ่าย',
      CASE_REPORT: 'รายงานจำนวนเคส',
      SETTING_CLINIC_INFORMATION: 'ตั้งค่าข้อมูลคลินิก',
      SETTING_EXAMINATION_ROOM: 'ตั้งค่าห้องตรวจ',
      SETTING_USER: 'ตั้งค่าผู้ใช้งาน',
      SETTING_MEDICINE_EQUIPMENT: 'ตั้งค่ายา / อุปกรณ์',
      NEW_MEDICINE_EQUIPMENT: 'เพิ่มยา / อุปกรณ์',
      MEDICINE_EQUIPMENT_INFORMATION: 'ข้อมูลยา / อุปกรณ์',
      SETTING_MEDICINE_PACK: 'ตั้งค่าการจัดชุดยา',
      SETTING_MEDICINE_EQUIPMENT_CATEGORIES: 'ตั้งค่าหมวดหมู่ยา / อุปกรณ์',
      SETTING_MEDICINE_EQUIPMENT_TYPE: 'ตั้งค่าประเภทยา / อุปกรณ์',
      SETTING_MEDICAL_PROCEDURE: 'ตั้งค่าหัตถการ',
      NEW_MEDICAL_PROCEDURE: 'เพิ่มหัตถการ',
      MEDICAL_PROCEDURE_INFORMATION: 'ข้อมูลหัตถการ',
      SETTING_MEDICAL_PROCEDURE_CATEGORIES: 'ตั้งค่าหมวดหมู่หัตถการ',
      SETTING_DOCTOR_FEE: 'ตั้งค่าค่าแพทย์',
      SETTING_PRODUCT: 'ตั้งค่าสินค้า',
      SETTING_PERMISSION: 'ตั้งค่าสิทธิ์เข้าถึง',
      SETTING_LIST_PERMISSION: 'รายการสิทธิ์เข้าถึง',
      NEW_PRODUCT: 'เพิ่มสินค้า',
      PRODUCT_INFORMATION: 'ข้อมูลสินค้า',
      SETTING_PRODUCT_CATEGORIES: 'ตั้งค่าหมวดหมู่สินค้า',
      EXAMINED_PATIENTS: 'คนไข้ที่เคยตรวจ / รับผิดชอบ',
      EXAMINATION_ROOM_INFO: 'รายการข้อมูลผู้ป่วย',
      SETTING_IMPORT_STOCK: 'รายการสินค้านำเข้า',
      SETTING_EXPORT_STOCK: 'รายการสินค้านำออก',
    },
    SWEETALERT: {
      CONFIRMATION_TITLE: 'การยืนยัน',
      CONFIRMATION_TEXT: 'คุณแน่ใจหรือว่าต้องการดำเนินการต่อ?',
      CONFIRMATION_DELETE_TEXT: 'คุณแน่ใจหรือว่าต้องการลบสิ่งนี้?',
      CREATE_AN_ACCOUNT: 'สร้างบัญชีผู้ใช้',
      CREATE_AN_ACCOUNT_TEXT: 'คุณแน่ใจหรือว่าต้องการสร้างบัญชีผู้ใช้นี้?',
      CREATE_AN_ACCOUNT_SUCCESS: 'สร้างบัญชีผู้ใช้เรียบร้อยแล้ว',
      UPDATE_AN_ACCOUNT: 'อัปเดตบัญชีผู้ใช้',
      UPDATE_AN_ACCOUNT_TEXT: 'คุณแน่ใจหรือว่าต้องการอัปเดตบัญชีผู้ใช้นี้?',
      NEW_CUSTOMER_PATIENT_REGISTER: 'ลงทะเบียนลูกค้า / ผู้ป่วยใหม่',
      NEW_CUSTOMER_PATIENT_REGISTER_TEXT: 'คุณแน่ใจหรือว่าต้องการยืนยันการลงทะเบียน',
      NEW_CUSTOMER_PATIENT_REGISTER_SUCCESS: 'ลงทะเบียนเรียบร้อยแล้ว',
      UPDATE_CUSTOMER_PATIENT: 'อัปเดตข้อมูลลูกค้า / ผู้ป่วย',
      NEW_OPD_CARD_TITLE: 'เปิด OPD Card',
      NEW_OPD_CARD_TEXT: 'คุณแน่ใจหรือว่าต้องการเปิด OPD Card',
      NEW_OPD_CARD_SUCCESS: 'เปิด OPD Card ใหม่เรียบร้อยแล้ว 📋',
      FINISH_OPD_CARD_TITLE: 'เสร็จสิ้นการตรวจ',
      FINISH_OPD_CARD_TEXT: 'คุณแน่ใจหรือว่าต้องการเสร็จสิ้นการตรวจ',
      SAVE_AND_FINISH_OPD_CARD_TITLE: 'บันทึกและเสร็จสิ้นการตรวจ',
      SAVE_FINISH_OPD_CARD_TEXT: 'คุณแน่ใจหรือว่าต้องการบันทึกและเสร็จสิ้นการตรวจ',
      FINISH_OPD_CARD_SUCCESS: 'เสร็จสิ้นการตรวจเรียบร้อยแล้ว 📋',
      CANCEL_OPD_CARD_TITLE: 'ยกเลิก OPD Card',
      CANCEL_OPD_CARD_TEXT: 'คุณแน่ใจหรือว่าต้องการยกเลิก OPD Card',
      CANCEL_OPD_CARD_SUCCESS: 'ยกเลิก OPD Card เรียบร้อยแล้ว',
      SAVE_MEDICAL_CERT_TITLE: 'ใบรับรองแพทย์',
      SAVE_MEDICAL_CERT_TEXT: 'ต้องการบันทึกใบรับรองแพทย์หรือไม่',
      SAVE_MEDICAL_CERT_SUCCESS: 'บันทึกใบรับรองแพทย์เรียบร้อยแล้ว 📋',
      UPDATE_CLINIC: 'อัปเดตข้อมูลคลินิก',
      NEW_MEDICINE_REGISTER: 'ลงทะเบียนยา / อุปกรณ์',
      NEW_PRODUCT_REGISTER: 'ลงทะเบียนสินค้า',
      CREATE_DATA: 'สร้างข้อมูล',
      CREATE_DATA_TEXT: 'คุณแน่ใจหรือว่าต้องการสร้างข้อมูลนี้?',
      UPDATE_DATA: 'อัปเดตข้อมูล',
      UPDATE_DATA_TEXT: 'คุณแน่ใจหรือว่าต้องการอัปเดตข้อมูลนี้?',
      SUCCESS_TITLE: 'สำเร็จ',
      SUCCESS_TEXT: 'สร้างข้อมูลใหม่เรียบร้อยแล้ว 😀',
      SUCCESS_TEXT_UPDATE: 'ข้อมูลได้อัปเดตเรียบร้อยแล้วแล้ว 😀',
      SUCCESS_TEXT_DELETE: 'ข้อมูลได้ถูกลบเรียบร้อยแล้ว 🗑️',
      ERROR_TITLE: 'ไม่สำเร็จ',
      ERROR_TEXT: 'ดูเหมือนว่ามีบางอย่างผิดพลาด 😢',
      ERROR_TEXT_LIMIT_DOCTOR: 'ถึงขีดจำกัดการเปิดใช้งานสำหรับ Doctor แล้ว 😢',
      ERROR_TEXT_LIMIT_ADMIN: 'ถึงขีดจำกัดการเปิดใช้งานสำหรับ Administrator แล้ว 😢',
      ERROR_TEXT_LIMIT_STAFF: 'ถึงขีดจำกัดการเปิดใช้งานสำหรับ Staff แล้ว 😢',
      ERROR_CN: 'หมายเลข CN นี้ถูกใช้ไปแล้ว',
      WARNING_TITLE: 'คำเตือน',
      WARNING_TEXT: 'มีบางอย่างไม่ถูกต้อง อาจทำให้คุณไม่สามารถดำเนินการต่อได้ ⛔',
      WARNING_IMAGE_SIZE_TITLE: 'รูปภาพมีขนาดใหญ่เกินไป',
      WARNING_IMAGE_SIZE_TEXT: 'กรุณาตรวจสอบขนาดรูปภาพต้องไม่เกิน {{value}} {{unit}}.',
      PAYMENT_SUCCESS: 'ชำระเงินเรียบร้อย',
      MEDICINE_MASTER_REQUIRED: 'หากยังไม่มีข้อมูล "หมวดหมู่ยา" หรือ "ประเภทยา" กรุณาเพิ่มข้อมูลก่อนสร้างรายการ',
      PROCEDURE_MASTER_REQUIRED: 'หากยังไม่มีข้อมูล "หมวดหมู่หัตถการ" กรุณาเพิ่มข้อมูลก่อนสร้างรายการ',
      PRODUCT_MASTER_REQUIRED: 'หากยังไม่มีข้อมูล "หมวดหมู่สินค้า" กรุณาเพิ่มข้อมูลก่อนสร้างรายการ',
      RE_LOGIN_FOR_PERMISSION: 'กรุณาแจ้งผู้ใช้งานให้เข้าสู่ระบบใหม่อีกครั้งเพื่ออัปเดตสิทธิ์เข้าถึงให้เป็นปัจจุบัน',
      SELECT_MEDICAL_CERT: 'เลือกใบรับรองแพทย์เพื่อแสดงข้อมูล',
      SELECT_OPD_BILL: 'เลือกใบเสร็จ OPD เพื่อแสดงข้อมูล',
      ADDED_APPOINTMENT: 'บันทึกการนัดหมายเรียบร้อยแล้ว 🗓️',
    },
    STATUS: {
      ALL: 'ทั้งหมด',
      COMPLETED: 'เสร็จสิ้น',
      CANCEL: 'ยกเลิก',
      BEING_EXAMINED: 'กำลังรับการตรวจ',
      WAITING_FOR_EXAMINATION: 'รอรับการตรวจ',
      ACTIVE: 'เปิดใช้งาน',
      INACTIVE: 'ปิดใช้งาน',
    },
    VALIDATOR: {
      REQUIRED: 'กรุณากรอกข้อมูล',
      MUST_BE_AT_LEAST_X_CHARACTERS: 'จำนวนตัวอักษรอย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_LOWER_CASE: 'ตัวพิมพ์เล็ก [a-z] อย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_LETTER_IN_UPPER_CASE: 'ตัวพิมพ์ใหญ่ [A-Z] อย่างน้อย {{value}} ตัว',
      MUST_CONTAIN_AT_LEAST_X_NUMBER: 'ตัวเลข [0-9] อย่างน้อย {{value}} ตัว',
      PLEASE_SELECT_YOUR_ROLE: 'กรุณาเลือกบทบาทของคุณ',
      INVALID_EMAIL: 'กรุณากรอกอีเมลที่ถูกต้อง',
      PASSWORD_MISMATCH: 'รหัสผ่านไม่ตรงกัน',
      PASSWORD_INVALID: "รูปแบบ 'รหัสผ่าน' ไม่ถูกต้อง",
      PLEASE_SELECT: 'กรุณาเลือก',
      PLEASE_SELECT_ROOM: 'กรุณาเลือกห้องตรวจ',
      PLEASE_SELECT_DOCTOR: 'กรุณาเลือกแพทย์ผู้รับผิดชอบ',
      REQUIRED_LABEL: 'กรุณาเพิ่มฉลากยา',
      INVALID_PAY_AMOUNT: 'จำนวนเงินไม่ถูกต้อง',
    },
    APP: {
      ATTACHMENTS: 'ไฟล์แนบ',
      FILE_PLACEHOLDER_2: 'ลากและวางไฟล์ลงที่นี่หรือ',
      SEARCH: 'ค้นหา',
      SEARCH_PLACEHOLDER: 'ค้นหา...',
      INLINE_SEARCH: 'ค้นหา:',
      DATE_RANGE: 'ช่วงวันที่',
      INLINE_DATE_RANGE: 'ช่วงวันที่:',
      INLINE_DATE: 'วันที่:',
      INLINE_MONTH: 'เดือน:',
      INLINE_YEAR: 'ปี:',
      INLINE_FILE_EXAMINATION: 'ไฟล์การตรวจร่างกาย:',
      GENERAL_INFORMATION: 'ข้อมูลทั่วไป',
      CN: 'CN',
      CID: 'CID',
      ID_CARD_NO: 'เลขที่ประจำตัวบัตรประชาชน',
      NAME: 'ชื่อ',
      NAME_TH: 'ชื่อ (ไทย)',
      LASTNAME_TH: 'นามสกุล (ไทย)',
      NAME_EN: 'ชื่อ (อังกฤษ)',
      LASTNAME_EN: 'นามสกุล (อังกฤษ)',
      NAME_TITLE: 'คำนำหน้า',
      BIRTHDAY: 'วันเกิด',
      BIRTHDAY_FORMAT: 'วัน-เดือน-ปี(พ.ศ.)',
      AGE: 'อายุ',
      YEAR: 'ปี',
      MONTH: 'เดือน',
      DAY: 'วัน',
      BLOOD_GROUP: 'หมู่เลือด',
      GENDER: 'เพศ',
      MARITAL_STATUS: 'สถานภาพการสมรส',
      RACE: 'เชื้อชาติ',
      CAREER: 'อาชีพ',
      EDUCATION: 'การศึกษา',
      MALE: 'ชาย',
      FEMALE: 'หญิง',
      ACCIDENT_AND_SURGERY: 'อุบัติเหตุและผ่าตัด',
      EPILEPSY: 'โรคลมชัก',
      CONGENITAL_DISEASE: 'โรคประจำตัว',
      REGULAR_MEDICATION: 'ยาที่ใช้ประจำ',
      SMOKING_HISTORY: 'ประวัติการสูบบุหรี่',
      NO_SMOKING: 'ไม่สูบบุหรี่',
      SMOKE_SOMETIMES: 'สูบบุหรี่บางครั้ง',
      SMOKING: 'สูบบุหรี่',
      ALCOHOL_CONSUMPTION_HISTORY: 'ประวัติการดื่มสุรา',
      DONT_DRINK_ALCOHOL: 'ไม่ดื่มสุรา',
      DRINK_ALCOHOL_SOMETIMES: 'ดื่มสุราบางครั้ง',
      DRINK_ALCOHOL: 'ดื่มสุรา',
      ALLERGY_INFORMATION: 'ข้อมูลการแพ้ยา',
      NO_HISTORY_OF_DRUG_ALLERGY: 'ไม่มีประวัติแพ้ยา',
      DONT_KNOW: 'ไม่ทราบ',
      DRUG_ALLERGY: 'แพ้ยา',
      ALLERGIC_DRUG_NAME: 'ชื่อยาที่แพ้',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'อาการเมื่อแพ้ยา',
      ALLERGIC_DRUG_GROUP: 'กลุ่มยาที่แพ้',
      ADDRESS: 'ที่อยู่',
      ADDRESS_INFORMATION: 'ข้อมูลการติดต่อ',
      PHONE: 'เบอร์ติดต่อ',
      PHONE_NO: 'เบอร์ติดต่อ {{no}}',
      PHONE_1: 'เบอร์ติดต่อ 1',
      PHONE_2: 'เบอร์ติดต่อ 2',
      LINE_ID: 'LINE ID',
      EMAIL: 'อีเมล',
      CONTACT_NUMBER: 'เบอร์ติดต่อ',
      CONTACT_NAME: 'ชื่อผู้ที่ติดต่อได้',
      RELATIONSHIP: 'ความสัมพันธ์',
      EMERGENCY_CASE: 'กรณีฉุกเฉิน',
      ADDRESS_ACCORDING_TO_ID_CARD: 'ที่อยู่ตามบัตรประชาชน',
      CURRENT_ADDRESS: 'ที่อยู่ปัจจุบัน',
      COPY_FROM_ID_CARD: 'คัดลอกจากที่อยู่ตามบัตรประชาชน',
      ADDRESS_NO: 'เลขที่',
      VILLAGE_NO: 'หมู่ที่',
      VILLAGE_BUILDING: 'หมู่บ้าน / อาคาร',
      ALLEY: 'ตรอก / ซอย',
      ALLEY_PREFIX_1: 'ซอย',
      ROAD: 'ถนน',
      SUB_DISTRICT: 'ตำบล / แขวง',
      SUB_DISTRICT_PREFIX_1: 'ตำบล',
      SUB_DISTRICT_PREFIX_2: 'แขวง',
      DISTRICT: 'อำเภอ / เขต',
      DISTRICT_PREFIX_1: 'อำเภอ',
      DISTRICT_PREFIX_2: 'เขต',
      PROVINCE: 'จังหวัด',
      POST_CODE: 'รหัสไปรษณีย์',
      MEDICAL_TREATMENT_RIGHTS: 'สิทธิ์การรักษาพยาบาล',
      TREATMENT_RIGHTS: 'สิทธิ์การรักษา',
      HOSPITAL: 'โรงพยาบาล',
      OPD_HISTORY: 'ประวัติการตรวจ',
      HISTORY: 'ประวัติ',
      RECEIPT: 'ใบเสร็จ',
      MEDICAL_CERTIFICATE: 'ใบรับรองแพทย์',
      NO_DATA: 'ไม่มี',
      SERVICE_DATE: 'วันที่รับบริการ',
      OPD_CARD_REQUEST: 'ประเภทการรับบริการ',
      EXAMINATION_ROOM: 'ห้องตรวจ',
      RESPONSIBLE_DOCTOR: 'แพทย์ผู้รับผิดชอบ',
      SAVED_BY: 'บันทึกโดย',
      WEIGHT_LABEL: 'น้ำหนัก (WT)',
      HEIGHT_LABEL: 'ส่วนสูง (HT)',
      BMI_LABEL: 'ดัชนีมวลกาย (BMI)',
      BODY_TEMP_LABEL: 'อุณหภูมิ (BT)',
      PULSE_LABEL: 'ชีพจร (PR)',
      BP_LABEL: 'ความดันโลหิต (BP)',
      RR_LABEL: 'อัตราการหายใจ (RR)',
      FBS_LABEL: 'ระดับน้ำตาลในเลือด (FBS)',
      CC_LABEL: 'CC:',
      HPI_LABEL: 'HPI:',
      PMH_LABEL: 'PMH:',
      PE_LABEL: 'PE:',
      DX_LABEL: 'Dx:',
      TX_LABEL: 'Tx:',
      UNIT_KG: 'กก.',
      UNIT_CM: 'ซม.',
      UNIT_CELSIUS: '°C',
      UNIT_PER_MIN: '/นาที',
      CC: 'อาการสำคัญ',
      HPI: 'ประวัติป่วยปัจจุบัน',
      PMH: 'ประวัติป่วยในอดีต',
      ADD_NOTE: 'เพิ่มเติม',
      PHYSICAL_EXAMINATION: 'การตรวจร่างกาย',
      DIAGNOSIS_AND_TREATMENT: 'การวินิจฉัยโรคและการรักษา',
      APPOINTMENT_RECORD: 'บันทึกการนัดหมาย',
      APPOINTMENT_DATE: 'วันที่นัดหมาย',
      APPOINTMENT_NOTE: 'หมายเหตุการนัดหมาย',
      DOC_NO: 'เลขที่',
      ISSUE_DATE: 'วันที่ออก',
      CUSTOMER: 'ลูกค้า',
      PAYMENT_DETAILS: 'รายละเอียดการชำระ',
      PAYMENT_METHODS: 'ช่องทางการชำระ',
      ACCOUNT_NUMBER: 'หมายเลขบัญชี',
      BANK: 'ธนาคาร',
      ACCOUNT_NAME: 'ชื่อบัญชี',
      ACCOUNT_NO: 'เลขที่บัญชี',
      USED_FOR: 'ใช้สำหรับ',
      SALESPERSON: 'พนักงาน / ผู้ดำเนินการ',
      BOOK_NO: 'เล่มที่',
      OPD_REFERENCE: 'อ้างอิงจาก OPD',
      EXAMINATION_PLACE: 'สถานที่ตรวจ',
      EXAMINATION_DATE: 'วันที่ตรวจ',
      EXAMINATION_DOCTOR: 'แพทย์ผู้ตรวจ',
      MEDICAL_PROFESSIONAL_LICENSE_NO: 'ใบอนุญาตประกอบวิชาชีพเวชกรรม',
      MEDICAL_LOCATION: 'สถานที่ประกอบวิชาชีพเวชกรรม',
      NAME_OF_EXAMINEE: 'ชื่อผู้รับการตรวจ',
      ADDRESS_PLACE: 'สถานที่อยู่',
      CAN_BE_CONTACTED: 'ที่สามารถติดต่อได้',
      DOC_CONGENITAL_DISEASE: '1. โรคประจำตัว',
      DOC_ACCIDENT_AND_SURGERY: '2. อุบัติเหตุและผ่าตัด',
      DOC_HOSPITALIZED: '3. เคยเข้ารับการรักษาในโรงพยาบาล',
      HOSPITALIZED: 'เคยเข้ารับการรักษาในโรงพยาบาล',
      DOC_EPILEPSY: '4. โรคลมชัก',
      DOC_OTHER_IMPORTANT_HISTORY: '5. ประวัติอื่นที่สำคัญ',
      GENERAL_PHYSICAL_CONDITION: 'สภาพร่างกายทั่วไป',
      APPEARING_SYMPTOMS_OR_OTHER_DISEASES: 'ปรากฎอาการหรือโรคอื่นๆ',
      DOCTOR_ADVICE: 'ข้อแนะนำของแพnย์',
      ADD_TO_EXAMINATION_ROOM: 'เพิ่มไปยังห้องตรวจ',
      OPTIONAL: 'ไม่จำเป็นต้องกรอก',
      PLACEHOLDER_FILL: '- ระบุ -',
      TO: 'ถึง',
      STATUS: 'สถานะ',
      PHONE_AND_EMERGENCY: 'เบอร์ติดต่อ / เบอร์ฉุกเฉิน',
      HAVE_AN_APPOINTMENT: 'มีนัดหมาย',
      NO_APPOINTMENT: 'ไม่มีนัดหมาย',
      PROCEDURE: 'หัตถการ',
      MEDICINE_AND_EQUIPMENT: 'ยา / อุปกรณ์',
      MEDICINE_PACK: 'ชุดยา',
      DOCTOR_FEE: 'ค่าแพทย์',
      CATEGORY: 'หมวดหมู่',
      TYPE: 'ประเภท',
      MEDICINE: 'ยา',
      EQUIPMENT: 'อุปกรณ์',
      MEDICINE_LABEL: 'ฉลากยา',
      MEDICINE_LABEL_DETAIL: 'รายละเอียดฉลากยา',
      MEDICINE_LABEL_PREVIEW: 'ตัวอย่างฉลากยา',
      MEDICINE_NAME: 'ชื่อยา',
      USAGE_UNIT: 'หน่วยการใช้',
      DISPENSE_UNIT: 'หน่วยจ่าย',
      USAGE: 'วิธีใช้',
      FREQUENCY: 'ความถี่',
      TIME: 'เวลา',
      EXPIRED: 'หมดอายุ',
      UNIT: 'หน่วย',
      DAYS: 'วัน',
      PERIOD: 'ระยะเวลา',
      DISPENSE_AMOUNT: 'จำนวนจ่าย',
      INDICATION: 'ข้อบ่งชี้',
      CAUTION: 'ข้อควรระวัง',
      DETAIL_ADVISE: 'ความคิดเห็น / คำแนะนำ',
      DETAIL_ADVISE_ADDON: 'ควรหยุดงานกี่วัน - เพราะเหตุใด',
      DIAGNOSE: 'วินิจฉัยโรค',
      DATE: 'วันที่',
      DOCTOR: 'แพทย์',
      OPD_CARD: 'OPD Card',
      YES: 'มี',
      NO: 'ไม่มี',
      NORMAL: 'ปกติ',
      ABNORMAL: 'ผิดปกติ',
      ANOMALY: 'ความผิดปกติ',
      MAIN_LOGO: 'โลโก้หลัก',
      POS_LOGO: 'โลโก้หน้าจอขาย',
      IMAGE_SIZE_LIMIT_MB: 'ขนาดรูปภาพไม่เกิน {{value}} MB.',
      IMAGE_SIZE_LIMIT: 'ขนาดรูปภาพไม่เกิน {{value}} {{unit}}.',
      CLINIC_CODE: 'รหัสคลินิก',
      CLINIC_NAME: 'ชื่อคลินิก',
      LICENSE_NO: 'เลขที่ใบอนุญาต',
      TYPE_OF_SERVICE: 'ลักษณะการให้บริการ',
      TAX_NO: 'เลขที่ประจำตัวผู้เสียภาษีอากร',
      FAX: 'แฟกซ์',
      PAYMENT_INFORMATION: 'ข้อมูลการชำระเงิน',
      ROLE: 'บทบาท',
      SHORT_NOTE: 'บันทึกย่อ',
      MEDICINE_USAGE: 'การใช้ยา',
      BAHT: 'บาท',
      NEW_APPOINTMENT: 'เพิ่มนัดหมายใหม่',
      APPOINTMENT: 'ข้อมูลนัดหมาย',

      //BOARD
      EMPTY_DOC: 'เอกสารเปล่า',
      CERTIFICATE_TYPE: 'ประเภทใบรับรอง',

      // REPORTS
      DATE_PERIOD: 'ช่วงวัน',
      MONTH_PERIOD: 'เดือน',
      TOTAL_INCOME: 'รายรับรวม',
      PROCEDURES: 'หัตถการ',
      DOCTOR_FEES: 'ค่าแพทย์',
      MEDICINE_EQUIPMENTS: 'ยา / อุปกรณ์',
      MEDICINES: 'ยา',
      EQUIPMENTS: 'อุปกรณ์',
      OTHER_PRODUCTS: 'สินค้าอื่นๆ',
      TOTOL_CASES: 'จำนวนเคสทั้งหมด',
      OLD_PATIENTS: 'ลูกค้าเก่า',
      NEW_PATIENTS: 'ลูกค้าใหม่',
      APPOINTMENTS: 'เช็คการนัดหมาย',
      FILTERS: 'ตัวกรอง',
      CUSTOMER_PATIENTS: 'ลูกค้า / ผู้ป่วย',
      COURSES: 'คอร์ส',

      // SETTINGS
      ENABLE: 'เปิดใช้งาน',
      DESCRIPTION: 'คำอธิบาย',
      TYPE_NAME: 'ชื่อประเภท',
      CATEGORY_NAME: 'ชื่อหมวดหมู่',
      MEDICINE_LIST: 'รายการยา',
      MEDICINE_PACK_NAME: 'ชื่อชุดยา',
      MEDICINE_CATEGORY: 'หมวดหมู่ยา',
      MEDICINE_TYPE: 'ประเภทยา',
      EQUIPMENT_CATEGORY: 'หมวดหมู่อุปกรณ์',
      EQUIPMENT_TYPE: 'ประเภทอุปกรณ์',
      TRADE_NAME: 'ชื่อการค้า',
      GENERIC_NAME: 'ชื่อสามัญ',
      GENERIC_NAME_ADDON: 'ชื่อสามัญ (สำหรับแสดงบนฉลากยา)',
      CODE: 'รหัส',
      BARCODE: 'บาร์โค้ด',
      LICENSEE: 'ผู้รับอนุญาตให้มีไว้ในครอบครอง',
      ACTING_TYPE: 'ประเภทวัตถุออกฤทธิ์',
      DETAIL: 'รายละเอียด',
      UNITS: 'หน่วยนับ',
      UNIT_NAME: 'ชื่อหน่วยนับ',
      MULTIPILIER: 'อัตรา/หน่วย',
      AMOUNT: 'จำนวน',
      MEDICINE_LABEL_DETIAL: 'ข้อมูลฉลากยา',
      DOCTOR_FEE_NAME: 'ชื่อค่าแพทย์',
      DOCTOR_LIST: 'รายการแพทย์',
      GROUP: 'กลุ่ม',
      NEW_PAYMENT_METHOD: 'เพิ่มช่องทางชำระเงิน',
      PAYMENT_METHOD_INFORMATION: 'ข้อมูลช่องทางการชำระเงิน',
      NEW_EXAMINATION_ROOM: 'เพิ่มห้องตรวจ',
      EXAMINATION_ROOM_INFORMATION: 'ข้อมูลห้องตรวจ',
      NEW_USER: 'เพิ่มผู้ใช้งาน',
      USER_INFORMATION: 'ข้อมูลผู้ใช้งาน',
      ACCOUNT_AND_PASSWORD: 'บัญชีและรหัสผ่าน',
      PASSWORD: 'รหัสผ่าน',
      CONFIRM_PASSWORD: 'ยืนยันรหัสผ่าน',
      NEW_MEDICINE_PACK: 'เพิ่มชุดยา',
      MEDICINE_PACK_INFORMATION: 'ข้อมูลชุดยา',
      NEW_MEDICINE_EQUIPMENT_CATEGORIES: 'เพิ่มหมวดหมู่ยา / อุปกรณ์',
      MEDICINE_EQUIPMENT_CATEGORIES_INFORMATION: 'ข้อมูลหมวดหมู่ยา / อุปกรณ์',
      NEW_MEDICINE_EQUIPMENT_TYPE: 'เพิ่มประเภทยา / อุปกรณ์',
      MEDICINE_EQUIPMENT_TYPE_INFORMATION: 'ข้อมูลประเภทยา / อุปกรณ์',
      MEDICAL_PROCEDURE_INFORMATION: 'ข้อมูลหัตถการ',
      MEDICAL_PROCEDURE_NAME: 'ชื่อหัตถการ',
      TIMES: 'จำนวนครั้ง',
      PER_TIME: 'ครั้ง',
      SELL_PRICE: 'ราคาขาย',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'ต้นทุนค่ายา/อุปกรณ์ (ต่อครั้ง)',
      MEDICINE_AND_EQUIPMENT_LIST: 'รายการยา / อุปกรณ์',
      NEW_MEDICINE_EQUIPMENT: 'เพิ่มยา / อุปกรณ์',
      USES_AMOUNTPER_TIME: 'จำนวนที่ใช้/ครั้ง',
      PRICE_PER_UNIT: 'ราคา/หน่วย',
      SUB_TOTAL: 'ราคารวม',
      NEW_MEDICAL_PROCEDURE_CATEGORIES: 'เพิ่มหมวดหมู่หัตถการ',
      MEDICAL_PROCEDURE_CATEGORIES_INFORMATION: 'ข้อมูลหมวดหมู่หัตถการ',
      NEW_DOCTOR_FEE: 'เพิ่มค่าแพทย์',
      DOCTOR_FEE_INFORMATION: 'ข้อมูลค่าแพทย์',
      PRODUCT_CATEGORY: 'หมวดหมู่สินค้า',
      NEW_PRODUCT_CATEGORIES: 'เพิ่มหมวดหมู่สินค้า',
      PRODUCT_CATEGORIES_INFORMATION: 'ข้อมูลหมวดหมู่สินค้า',
      LIST_MEDICINE_ALLERGY_INFORMATION: 'มีข้อมูลการแพ้ยา {{amount}} รายการ',
      LOADING: 'กำลังโหลด...',
      NONE: 'ไม่มี',
      BEFORE_DISCOUNT: 'ยอดก่อนลด',
      VN: 'VN',
      EXAMINATION_FILE: 'ไฟล์การตรวจร่างกาย',
      NO_ATTACH_FILE: 'ไม่มีไฟล์แนบ',
      MODULES: 'เมนูการใช้งาน',
      EDIT_MODE: 'โหมดแก้ไข',
      VIEW_MODE: 'ระยะเวลา',
      SEARCH_BY_NAME_CN: 'ค้นหาโดย ชื่อ หรือ CN',

      REQ_STOCK_IMPORT: 'รายการสินค้าที่ต้องการนำเข้า',
      REQ_STOCK_EXPORT: 'รายการสินค้าที่ต้องการนำออก',
      STOCK_IMPORT: 'รายการสินค้านำเข้า',
      STOCK_EXPORT: 'รายการสินค้านำออก',
      NEW_STOCK_IMPORT: 'เพิ่มรายการสินค้านำเข้า',
      NEW_STOCK_EXPORT: 'เพิ่มรายการสินค้านำออก',
      DOCUMENT_STOCK_DESCRIPTION: 'รายละเอียด',
      EXPORT_REASON: 'เหตุผลที่นำออก',

      //Dashboard
      PUT_OFF: 'เลื่อนนัด',
      TODAY: 'วันนี้',
      IN_ADVANCE: 'ล่วงหน้า',
      SEVEN_DAY: '7 วัน',
      FIFTEEN_DAY: '15 วัน',
      THIRTY_DAY: '30 วัน',
      WEEK: 'สัปดาห์',
      PLEASE_SELECT_LIST_TO_DISPLAY: 'เลือกรายการเพื่อแสดงผลกราฟ',
      PLEASE_SELECT_LIST: 'เลือกรายการ',
      DISPLAY_CHART: 'แสดงข้อมูล',

      PRODUCT_LIST: 'รายการสินค้า',
      PRODUCT_NAME: 'ชื่อสินค้า',
    },
    COMPONENT: {
      REGISTER: 'ลงทะเบียนใหม่',
      IMPORT_FILE_CSV: 'นำเข้าไฟล์ (CSV)',
      BROWSE_FILE: 'เลือกไฟล์',
      DETAIL: 'รายละเอียด',
      EDIT: 'แก้ไข',
      EDIT_DETAIL: 'แก้ไขข้อมูล',
      DELETE: 'ลบออก',
      READ_FROM_ID_CARD: 'อ่านจากบัตรประชาชน',
      SAVE: 'บันทึก',
      SAVE_CHANGES: 'บันทึกการเปลี่ยนแปลง',
      SAVE_AND_COMPLETE: 'บันทึกและเสร็จสิ้น',
      CANCEL: 'ยกเลิก',
      SELECT_IMAGE: 'เลือกรูปภาพ',
      NEW_OPD_CARD: 'เปิด OPD Card',
      PRINT_OPD_CARD: 'พิมพ์ OPD Card',
      PRINT: 'พิมพ์',
      PREVIEW: 'ดูตัวอย่างกระดาษ',
      DOWNLOAD: 'ดาวน์โหลด',
      OPERATE: 'เข้าตรวจ',
      VIEW_DETAIL: 'ดูรายละเอียด',
      SEE_MORE: 'ดูข้อมูลเพิ่มเติม',
      SEE_LESS: 'แสดงน้อยลง',
      COMPARE_HISTORY: 'เทียบประวัติเก่า',
      MEDICAL_CERTIFICATE: 'ออกใบรับรองแพทย์',
      ADD: 'เพิ่ม',
      ADD_MEDICINE_LABEL: 'เพิ่มฉลากยา',
      EDIT_MEDICINE_LABEL: 'แก้ไขฉลากยา',
      PRINT_MEDICINE_LABEL: 'พิมพ์ฉลากยา',
      COMPLETE_OPD: 'เสร็จสิ้นการตรวจ',
      CONFIRM: 'ยืนยัน',
      CHANGE_PASSWORD: 'เปลี่ยนรหัสผ่าน',
      CLOSE: 'ปิด',
      LOAD_MORE: 'โหลดเพิ่มเติม',
      ADD_PHONE: 'เพิ่มเบอร์ติดต่อ',
      ADD_HOSPITAL: 'เพิ่มโรงพยาบาล',
      CLEAR: 'ล้าง',
      APPLY: 'กรองข้อมูล',
      FILTERS: 'ตัวกรอง',
      CUSTOMER_PATIENT_DETAILS: 'รายละเอียดลูกค้า / ผู้ป่วย',
      OPEN_OPD: 'เปิดดู OPD Card',
      APPOINTMENT: 'การนัดหมาย',
      EXAMINATION_FILE: 'ไฟล์การตรวจร่างกาย',
      ALL: 'ทั้งหมด',
      SELECT_ALL: 'เลือกทั้งหมด',
      BACK_TO_HOME: 'กลับหน้าหลัก',
      NEW_APPOINTMENT: 'เพิ่มนัดหมายใหม่',
      BLANK_OPD_CARD: 'OPD Card เปล่า',
    },
    TABLE: {
      NO_DATA_DISPLAY: 'ไม่มีรายการแสดง',
      NO_DATA: 'ไม่มีรายการ',
      TOTAL: 'รวม',
      SUMMARY_TOTAL: 'รวมทั้งหมด',
      TOTAL_RESULT: 'ทั้งหมด',
      TOTAL_LIST: 'รายการ',
      CUSTOMER_PATIENT: 'ลูกค้า / ผู้ป่วย',
      AGE: 'อายุ',
      AGE_YEAR: 'อายุ (ปี)',
      ID: 'เลขที่ประจำตัวบัตรประชาชน',
      PHONE: 'เบอร์ติดต่อ',
      EMERGENCY: 'ฉุกเฉิน',
      LINE: 'LINE',
      LAST_UPDATED: 'แก้ไขล่าสุด',
      LAST_LOGIN: 'เข้าสู่ระบบล่าสุด',
      OPTION: 'ตัวเลือก',
      BY: 'by',
      CONNECTED: 'เชื่อมต่อแล้ว',
      NOT_CONNECTED: 'ไม่ได้เชื่อมต่อ',
      DOC_NO: 'เลขที่',
      DATE: 'วันที่',
      OPD_LIST: 'รายการตรวจ',
      DIAG_LIST: 'การรักษา',
      RESPONSIBLE_DOCTOR: 'แพทย์ผู้รับผิดชอบ',
      SUB_TOTAL: 'ราคารวม',
      NET_PRICE: 'ยอดรวม',
      PAID_PRICE: 'ยอดที่ต้องชำระ',
      STATUS: 'สถานะ',
      PAID: 'ชำระแล้ว',
      VOLUME_NO_DOC_NO: 'เล่มที่/เลขที่',
      REFERENCE: 'อ้างอิงจาก',
      ISSUED_BY: 'ออกให้โดย',
      ITEMS: 'รายการ',
      PRICE_PER_UNIT: 'ราคา/หน่วย',
      AMOUNT: 'จำนวน',
      MIN_AMOUNT: 'จำนวนขั้นต่ำ',
      DISCOUNT: 'ส่วนลด',
      BAHT: 'บาท',
      MEDICINE_USAGE: 'การใช้ยา',
      VAT: 'ภาษีมูลค่าเพิ่ม ({{value}})',
      SERVICE_DATE: 'วันที่รับบริการ',
      COMPLETED_DATE: 'วันที่รับบริการเสร็จสิ้น',
      EXAMINATION_ROOM: 'ห้องตรวจรักษา',
      CN: 'CN',
      VN: 'VN',
      ITEMS_SUM: 'ค่าตรวจรักษาและยา',

      // APPOINT
      APPOINTMENT_DATE: 'วันที่นัดหมาย',
      APPOINTMENT_DATETIME: 'วัน/เวลานัดหมาย',
      // REPORT
      RECEIPT_NO: 'เลขที่ใบเสร็จ',
      APPOINTMENT: 'นัดหมาย',

      // SETTING
      CODE: 'รหัส',
      PRICE: 'ราคา',
      UNIT: 'หน่วย',
      NUMBER_OF_MEDICINE: 'จำนวนรายการยา',
      ACCOUNT_NAME: 'ชื่อบัญชี',
      ACCOUNT_NO: 'เลขที่บัญชี',
      USED_FOR: 'ใช้สำหรับ',
      NAME: 'ชื่อ',
      NAME_CODE: 'ชื่อ / รหัส',
      LICENSE_NO: 'เลขที่ใบอนุญาต',
      EMAIL: 'อีเมล',
      ROLE: 'บทบาท',
      DESCRIPTION: 'คำอธิบาย',
      GROUP: 'กลุ่ม',
      CATEGORY: 'หมวดหมู่',
      TYPE: 'ประเภท',
      MEDICINE_ITEM_COUNT: 'จำนวนรายการยา',
      MEDICINE_CATEGORY: 'หมวดหมู่ยา',
      MEDICINE_TYPE: 'ประเภทยา',
      MEDICINE_NAME: 'ชื่อยา',
      DOCTOR: 'แพทย์',
      FEE: 'ค่าแพทย์',
      UNIT_NAME: 'ชื่อหน่วยนับ',
      PRIMARY_UNIT: 'หน่วยหลัก',
      MULTIPILIER: 'อัตรา/หน่วย',
      MEDICINE_EQUIPMENT_PRICE_PER_TIME: 'ค่ายา/อุปกรณ์ (/ครั้ง)',
      TIMES: 'จำนวนครั้ง',
      SELL_PRICE: 'ราคาขาย',
      USES_AMOUNTPER_TIME: 'จำนวนที่ใช้/ครั้ง',
      TYPE_OF_SERVICE: 'ลักษณะการให้บริการ',
      SHOW: 'แสดง',
      ENTRIES: 'รายการ',
      ALLERGIC_DRUG_NAME: 'ชื่อยาที่แพ้',
      SYMPTOMS_WHEN_ALLERGIC_TO_DRUGS: 'อาการเมื่อแพ้ยา',
      ALLERGIC_DRUG_GROUP: 'กลุ่มยาที่แพ้',

      // EXAMINATION FILE
      EXAMINATION_FILE: 'ไฟล์การตรวจร่างกาย',

      // DASHBOARD
      APPOINTMENT_TIME: 'เวลานัด',
      INCOME: 'รายรับ',
      EXPIRE_DATE: 'วันหมดอายุ',

      // SKU_MASTER
      UNIT_PRICE: 'ราคา/หน่วย',
      PRODUCT_NAME: 'ชื่อสินค้า',

      STOCK: 'สต๊อกสินค้า',
    },
    MISC: {
      COMING_SOON_TITLE: 'กำลังจะเปิดตัวเร็ว ๆ นี้',
      COMING_SOON_TEXT: 'เรากำลังปรับปรุงบางอย่างอยู่ คุณจะได้รับการแจ้งเตือนเมื่อพร้อม!',
      NOT_AUTHORIZED: 'คุณไม่สามารถเข้าถึงส่วนนี้ได้',
      NOT_AUTHORIZED_TEXT:
        'ดูเหมือนว่าบัญชีของคุณอาจมีการจำกัดการเข้าถึง หรืออาจมีปัญหาเกี่ยวกับสิทธิ์การใช้งาน กรุณาติดต่อผู้ดูแลเพื่อขอความช่วยเหลือ',
      BILL_FOOTER: 'หากมีข้อสงสัยหรือพบปัญหาติดต่อ',
      VERSION: 'เวอร์ชัน',
    },
    DATETIME: {
      MONTH: {
        JAN: 'ม.ค.',
        FEB: 'ก.พ.',
        MAR: 'มี.ค.',
        APR: 'เม.ย.',
        MAY: 'พ.ค.',
        JUN: 'มิ.ย.',
        JUL: 'ก.ค',
        AUG: 'ส.ค.',
        SEP: 'ก.ย.',
        OCT: 'ต.ค.',
        NOV: 'พ.ย.',
        DEC: 'ธ.ค.',
      },
      MONTH_FULL: {
        JAN: 'มกราคม',
        FEB: 'กุมภาพันธ์',
        MAR: 'มีนาคม',
        APR: 'เมษายน',
        MAY: 'พฤษภาคม',
        JUN: 'มิถุนายน',
        JUL: 'กรกฎาคม',
        AUG: 'สิงหาคม',
        SEP: 'กันยายน',
        OCT: 'ตุลาคม',
        NOV: 'พฤศจิกายน',
        DEC: 'ธันวาคม',
      },
    },
    POS: {
      BAHT: 'บาท',
      PAY: 'ชำระเงิน',
      PAYMENT: 'ชำระเงิน',
      PAYMENT_SUCCEED: 'เสร็จสิ้น',
      AMOUNT: 'ยอดที่ต้องชำระ',
      SUMMARY_PRICE: 'ยอดรวม',
      RECEIVED: 'จำนวนเงินที่รับ',
      CHANGE: 'เงินทอน',
      ELIGIBLE_HOSPITAL: 'โรงพยาบาลที่มีสิทธิ์',
      BANK_ACCOUNT: 'ชื่อบัญชีธนาคาร',
      CARD_TYPE: 'ประเภทบัตร',
      CREDIT_CHARGE: 'บัตรเครดิตชาร์จ',
      PAYMENT_CASH: 'เงินสด',
      PAYMENT_BANK: 'โอนเข้าธนาคาร',
      PAYMENT_CREDIT_CARD: 'บัตรเครดิต',
      PAYMENT_SOCIAL_SECURITY: 'ประกันสังคม',
      CUSTOMER: 'ลูกค้า',
      LIST: 'รายการ',
      UNIT_PRICE: 'ราคา/หน่วย',
      AMOUNT_ITEM: 'จำนวน',
      TOTAL: 'รวม',
      DISCOUNT: 'ส่วนลด',
      TOTAL_PRICE: 'ราคารวม',
      VAX: 'ภาษีมูลค่าเพิ่ม',
      PRICE_AFTER_DISCOUNT: 'หลังหักส่วนลด',
      OPD_RECEIPT: 'ใบเสร็จ OPD',
      WAIT_FOR_PAYMENT: 'รอชำระเงิน',
    },
    DASHBOARD: {
      NEW_PATIENT: 'สร้างผู้ป่วยใหม่',
      APPOINTMENT_CERTIFICATE: 'ออกใบนัด (สรุป/รายการยา)',
      PRINT_APPOINTMENT_CERT: 'พิมพ์ใบนัด',
      PAYMENT_LIST: 'รายการชำระเงิน',
      PAY: 'ชำระเงิน',
      PAYMENT: 'ชำระเงิน',
      PAYMENT_SUCCEED: 'ชำระเงินแล้ว',
      EXAMINATION_DOCTOR_FEE: 'ค่าตรวจ / ค่าแพทย์',
      EXAMINATION_PROCEDURES_FEE: 'ค่าหัตถการ',
      MEDICINE_FEE: 'ค่ายา',
      EQUIPMENT_FEE: 'ค่าอุปกรณ์',
      MEDICINE_EQUIPMENT_FEE: 'ค่ายา / ค่าอุปกรณ์',
      TOTAL_CUSTOMER: 'จำนวนลูกค้าทั้งหมด',
      TOTAL_NEW_CUSTOMER: 'จำนวนลูกค้าใหม่',
      TOTAL_EXAM_CUSTOMER: 'คนไข้ทั้งหมดที่ตรวจ',
      TOTAL_CARE_CUSTOMER: 'จำนวนคนไข้ทั้งหมดที่ดูแล',
      PAYMENT_SUMMARY: 'รายรับตามช่องทางชำระเงิน',
      INCOME_SUMMARY: 'รายรับรวม',
      BY_CATEGORY: 'หมวดหมู่หลัก',
      BY_ITEM: 'รายการ',
      TOP_5: '5 อันดับแรก',
      DOCTOR_STAFF_INCOME: 'รายรับแพทย์ / พนักงาน',
      MEDICINE_EQUIPMENT_SOLD: 'รายรับจ่ายยา / อุปกรณ์',
      EXPIRE_PRODUCT: 'รานงานสินค้าใกล้หมดอายุ / สินค้าเหลือน้อย',
      START_DATE: 'แสดงวันที่',
      TO_DATE: 'ถึง',
      GO_BACK: 'ย้อนหลัง',
      TOTAL_SALE: 'ยอดขายรวม',
      VIEW_MODE: 'มุมมอง',
      DATA_DATE: 'วันที่ข้อมูล',
      LIST: 'รายการ',
    },
    ROLE_MANAGEMENT: {
      PERMISSION_HEADER: {
        VIEW: 'ดู',
        CREATE: 'เพิ่ม',
        EDIT: 'แก้ไข',
        DELETE: 'ลบ',
      },
    },
  },
};
