<div class="card {{ classes }} skeleton-parent" [ngClass]="{ 'skeleton-parent': isLoading }">
  <div class="card-header">
    <div>
      <p class="card-text mb-25">{{ label | translate }}</p>
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    </div>
    <div class="avatar p-50 m-0 {{ iconClass }}">
      <!-- bg-light-primary -->
      <div class="avatar-content">
        <i [data-feather]="icon" class="font-medium-5"></i>
      </div>
    </div>
  </div>
</div>

<ng-template #info>
  <h2 class="font-weight-bold mb-0" *ngIf="data !== null">
    <ng-container [ngTemplateOutlet]="dataDisplay"></ng-container>
  </h2>
</ng-template>

<ng-template #dataDisplay>
  <ng-container *ngIf="!html; else htmlEl">
    <ng-container *ngIf="!decimals; else decimalsEl">
      <ng-container *ngIf="!mask; else maskEl">{{ data }}</ng-container>
    </ng-container>
  </ng-container>

  <ng-template #htmlEl>
    <span [innerHtml]="data" class="pre-line"></span>
  </ng-template>

  <ng-template #decimalsEl>
    {{ data | number : "1." + decimals + "-" + decimals }}
  </ng-template>

  <ng-template #maskEl>
    {{ data | mask : mask : separator }}
  </ng-template>
</ng-template>