import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { apiPaths } from './_api-path';

@Injectable({
  providedIn: 'root',
})
export class OpdService {
  private api = apiPaths;
  private excepted_key_names = [];
  private unspread_key_names = ['examination_files'];
  constructor(private http$: HttpClient) { }

  _setParams(value) {
    let params = new HttpParams();
    for (const key in value) {
      if (Array.isArray(value[key])) {
        params = params.append(key, JSON.stringify(value[key]));
      } else {
        params = params.append(key, value[key]);
      }
    }
    return params;
  }

  // _setFormData(object_key, value) {
  //   let formData = new FormData();
  //   for (const key in value) {
  //     formData.append(`${object_key}[${key}]`, value[key] === null ? '' : value[key]);
  //   }
  //   return formData;
  // }

  _setFormData(object_key, value) {
    let formData = new FormData();
    for (const key in value) {
      let obj = value[key];
      if (Array.isArray(obj)) {
        obj.forEach((e, i) => {
          formData.append(`${object_key}[${key}][]`, e);
        });
      } else if (Array.isArray(value)) {
        for (const key_i in obj) {
          let obj_i = obj[key_i];
          if (Array.isArray(obj_i)) {
            obj_i.forEach((ej, j) => {
              formData.append(`${object_key}[][${key_i}][]`, ej);
            });
          } else {
            formData.append(`${object_key}[][${key_i}]`, obj_i);
          }
        }
      } else {
        formData.append(`${object_key}[${key}]`, value[key]);
      }
    }
    return formData;
  }

  getOpds(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.opdCardApi}?${params}`).pipe(retry(1));
  }

  getOpdCount(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`/opd_cards/count?${params}`).pipe(retry(1));
  }

  getNewOpdCard(): Observable<any> {
    return this.http$.get(`${this.api.opdCardApi}/new`).pipe(retry(1));
  }

  opdCard(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.opdCardApi}/${id}?${params}`).pipe(retry(1));
  }

  createOpdCard(payload?): Observable<any> {
    const formData = this._setFormData('opd_card', payload);
    return this.http$.post(`${this.api.opdCardApi}`, formData).pipe(retry(1));
  }

  updateOpdCard(id?, payload?): Observable<any> {
    const data = { opd_card: payload };
    // const formData = this._setFormData('opd_card', payload);
    return this.http$.put(`${this.api.opdCardApi}/${id}`, data).pipe(retry(1));
  }

  examiningOpdCard(id): Observable<any> {
    return this.http$.get(`${this.api.opdCardApi}/${id}/examining`).pipe(retry(1));
  }

  cancelOpdCard(id): Observable<any> {
    return this.http$.get(`${this.api.opdCardApi}/${id}/cancel`).pipe(retry(1));
  }

  waitMedicineOpdCard(id: number): Observable<any> {
    return this.http$.get(`${this.api.opdCardApi}/${id}/wait_medicine`);
  }

  finishOpdCard(id): Observable<any> {
    return this.http$.get(`${this.api.opdCardApi}/${id}/finish`).pipe(retry(1));
  }
  createAllExaminationFile(payload?): Observable<any> {
    const formData = this._setFormData('examination_files', payload);
    return this.http$.post(`${this.api.opdAllExaminationFileApi}`, formData).pipe(retry(1));
  }
  deleteExaminationFile(id): Observable<any> {
    return this.http$.delete(`${this.api.opdExaminationFileApi}/${id}`).pipe(retry(1));
  }
  // MEDICAL CERTIFICATE
  getMedicalCertificates(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalCertificatesApi}?${params}`).pipe(retry(1));
  }
  medicalCertificate(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalCertificatesApi}/${id}?${params}`).pipe(retry(1));
  }
  createMedicalCertificate(payload?): Observable<any> {
    const formData = this._setFormData('medical_certificate', payload);
    return this.http$.post(`${this.api.medicalCertificatesApi}`, formData).pipe(retry(1));
  }
  updateMedicalCertificate(id?, payload?): Observable<any> {
    const data = { medical_certificate: payload };
    return this.http$.put(`${this.api.medicalCertificatesApi}/${id}`, data).pipe(retry(1));
  }

  // MEDICAL RECEIPT
  getMedicalReceipts(filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalReceiptsApi}?${params}`).pipe(retry(1));
  }
  getMedicalReceipt(id?, filters?): Observable<any> {
    const params = this._setParams(filters);
    return this.http$.get(`${this.api.medicalReceiptsApi}/${id}?${params}`).pipe(retry(1));
  }
}
